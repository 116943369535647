
.news-detail-container {
    background: #f2f2f2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .news-detail {
        width: 1100px;
        background: #fff;
        margin: 50px auto;
        box-shadow: 0 2px 10px 0 rgba(32,32,32,0.05);
        padding: 40px 50px;
        .news-title {
            text-align: center;
            font-size: 30px;
            color: #000;
            margin-bottom: 20px;
        }
        .news-content {
            ::v-deep div {
                img {
                    max-width: 100%;
                }
            }
        }
    }
}
